import { graphql } from "gatsby";
import React from "react";
import HeroVector from "../assets/competitor_vector.svg";
import { GradientNextIcon } from "../assets/icons";
import MobVector from "../assets/mobVector.svg";
import TabVector from "../assets/tabVector.svg";
import AnnotationTypes from "../components/AnnotationTypes";
import CompetitorTable from "../components/CompetitorTable";
import CustomerRating from "../components/CustomerRating";
import MatricsCard from "../components/MatricsCard";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import TrustedBrands from "../components/TrustedBrands";
import SideCard from "../components/home_Pages/SideCard";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/page/competitor_page.scss";
import { pickSlice } from "../utilities/helpers";
import VideoComponent from "../components/VideoComponent";

const Competitor = ({ location, data }) => {
  const _data =
    data?.allPrismicNewCompetitorLandingPage &&
    !!data.allPrismicNewCompetitorLandingPage?.nodes.length &&
    data.allPrismicNewCompetitorLandingPage?.nodes[0]?.data;
  const [trusted_brands_list] = pickSlice(_data, "trusted_brands_list");
  const [annotation_types] = pickSlice(_data, "annotation_types");
  const [feature_table] = pickSlice(_data, "feature_table");
  const repeatable_section = pickSlice(_data, "repeatable_section");
  const [repeatable_cards] = pickSlice(_data, "repeatable_cards");
  const [quality_metrics] = pickSlice(_data, "quality_metrics");
  const [reviews] = pickSlice(_data, "reviews");
  const encord_annotate_sideCards = repeatable_cards?.items?.filter(
    (item) => item?.parent_section === "ENCORD ANNOTATE"
  );
  const encord_active_sideCards = repeatable_cards?.items?.filter(
    (item) => item?.parent_section === "ENCORD ACTIVE"
  );
  const encord_annotate = repeatable_section.find(
    (item) => item?.primary?.type === "ENCORD ANNOTATE"
  );
  const encord_active = repeatable_section.find(
    (item) => item?.primary?.type === "ENCORD ACTIVE"
  );

  return (
    <Layout location={location}>
      <main className="overflow-hidden">
        <div className="relative">
          <img
            className="absolute object-cover w-full h-80 top-44 -z-10"
            width={0}
            height={0}
            src={HeroVector}
            alt="img"
          />
          <article className="px-2 pt-16 mx-auto max-w-7xl md:pt-32 lg:px-0 sm:px-12">
            <section className="flex flex-col max-w-2xl mx-auto mb-5 gap-y-8 lg:mt-0 mt-14">
              <div
                dangerouslySetInnerHTML={{ __html: _data?.page_heading.html }}
                className="h1_gradient text-2.5xl sm:text-3.25xl lg:leading-tight tracking-tightest sm:text-4.5xl font-semibold text-purple-1000 text-center"
              />

              <p className="px-4 text-base text-center text-purple-1000 lg:text-xl sm:px-0">
                {_data?.page_description?.text}
              </p>
              <section className="flex justify-center">
                <NewHomeLeadFormWithAPIForm
                  location={location}
                  btnText="Book a demo"
                  isCustomBtnBg={true}
                  btnColor="btn_gradient border-0 px-8 h-[50px]"
                />
              </section>
            </section>

            {_data?.hero_section_video && (
              <section className="lg:px-14">
                <VideoComponent
                  loop
                  autoPlay
                  muted
                  className={`mx-auto w-full h-auto rounded-lg shadow-card`}
                >
                  <source
                    src={_data?.hero_section_video?.url}
                    type="video/mp4"
                  />
                </VideoComponent>
              </section>
            )}

            {feature_table && <CompetitorTable feature_table={feature_table} />}

            {trusted_brands_list && (
              <section className="lg:my-16 sm:mt-9 sm:mb-16 mb-9">
                <TrustedBrands trusted_brands_list={trusted_brands_list} />
              </section>
            )}
          </article>
        </div>

        <section className="py-1 bg-gray-1500">
          <article className="px-2 mx-auto mb-5 max-w-7xl sm:mb-16 lg:px-0 sm:px-12">
            {encord_annotate && (
              <section className="flex flex-col items-center max-w-lg mx-auto mt-10 lg:mb-16 sm:mb-10 mb-14 md:my-16">
                {encord_active && (
                  <img src={encord_active?.primary?.label_icon?.url} alt="img"/>
                )}
                <h3 className="text-[#5E44FF] uppercase text-p text-sm font-medium pt-2.5">
                  {encord_annotate?.primary?.type}
                </h3>
                <h4
                  className={`text-[#434343] text-2.5xl sm:text-3.5xl tracking-tighter_1 leading-10 font-semibold pt-5 text-center ${
                    encord_annotate?.primary?.section_heading?.text.length > 65
                      ? "max-w-lg"
                      : "max-w-md"
                  }`}
                >
                  {encord_annotate?.primary?.section_heading?.text}
                </h4>
                <p className="px-4 py-5 text-base text-center text-gray-600 sm:text-xl sm:px-0 tracking-tighter_1">
                  {encord_annotate?.primary?.section_description?.text}
                </p>
                <a
                  href={encord_annotate?.primary?.button_link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn_gradient text-white btn h-[50px]"
                >
                  {encord_annotate?.primary?.button_text?.text}
                </a>
              </section>
            )}

            {annotation_types && (
              <AnnotationTypes
                annotation_types={annotation_types}
                labelClass="text-[#5E44FF] tracking-wider"
                activeAnnotationBg="bg-[#5E44FF]"
                linkClass="link_gradient"
                linkIcon={GradientNextIcon}
                isMediaRounded={true}
                headingClass="tracking-tighter_3"
                pargraphClass="tracking-tighter_1"
              />
            )}
            {encord_annotate_sideCards && (
              <section className="flex flex-col lg:flex-row gap-x-6">
                {encord_annotate_sideCards?.map((card, index) => {
                  return (
                    <SideCard
                      key={index}
                      data={{ ...card, learn_more_link: card?.learn_more }}
                      containerClasses="!h-auto"
                      imageClasses="mx-5"
                      labelClass="text-[#5E44FF] tracking-wider"
                      linkClass="link_gradient"
                      linkIcon={GradientNextIcon}
                    />
                  );
                })}
              </section>
            )}
          </article>
        </section>
        {reviews && (
          <article className="relative px-2 mx-auto mt-24 max-w-7xl lg:px-0 sm:px-12 lg:mb-20 md:mb-24 mb-9">
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 review_bg top-1/2 left-1/2 -z-10" />

            <CustomerRating reviews={reviews} />
          </article>
        )}

        <article className="relative overflow-hidden encord_active_gradient">
          <img
            className="absolute z-10 hidden w-full transform rotate-180 h-80 bottom-80 -scale-y-100 lg:block"
            width={0}
            height={0}
            src={HeroVector}
            alt="heroverctor"
          />
          <img
            className="absolute h-80 w-full bottom-[450px] transform z-10 lg:hidden sm:block hidden"
            width={0}
            height={0}
            src={TabVector}
            alt="tabvector"
          />
          <img
            className="absolute z-10 w-full transform h-80 bottom-11 sm:hidden"
            width={0}
            height={0}
            src={MobVector}
            alt="mobvector"
          />
          <div className="absolute top-0 left-0 z-0 transform -translate-y-1/2 encord_top_bg -translate-x-2/3" />

          <div className="absolute z-0 transform -translate-x-1/2 encord_center_bg lg:top-1/2 top-1/3 lg:-translate-y-1/2 -translate-y-2/4 left-1/2" />
          <div className="absolute z-20 hidden transform encord_bottom_right_bg lg:-bottom-80 lg:-translate-x-1/2 lg:left-1/2 sm:bottom-40 sm:left-1/2 left-2/3 lg:hidden sm:block" />

          <div className="absolute z-20 transform encord_bottom_bg lg:-bottom-80 lg:-translate-x-1/2 lg:left-1/2 sm:bottom-40 sm:left-1/3 -bottom-20" />
          <section className="relative z-20 px-5 py-4 mx-auto mb-4 max-w-7xl">
            {encord_active && (
              <section className={`flex flex-col items-center mx-auto my-10 text-center text-white lg:mt-20 lg:mb-24 ${encord_active?.primary?.section_heading?.text.length > 52
                ? "max-w-2xl px-1"
                : "max-w-lg px-1"
            }}`}>
                <img src={encord_active?.primary?.label_icon?.url} alt="img"/>

                <h3 className="text-[#C5BBFF] tracking-wider uppercase text-sm font-medium pt-2.5">
                  {encord_active?.primary?.type}
                </h3>

                <h4 className={`text-2xl sm:text-3.5xl font-semibold pt-5 text-white text-center tracking-tighter_3 ${encord_active?.primary?.section_heading?.text.length > 52 && "sm:px-9"
            }`}>
                  {encord_active?.primary?.section_heading?.text}
                </h4>
                <p className="py-5 text-xl font-normal text-gray-1400 max-w-lg">
                  {encord_active?.primary?.section_description?.text}
                </p>

                <a
                  href={encord_active?.primary?.button_link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn_gradient text-white btn h-[50px]"
                >
                  {encord_active?.primary?.button_text?.text}
                </a>
              </section>
            )}

            {quality_metrics && (
              <MatricsCard
                quality_metrics={quality_metrics}
                cardClass="encord_active_card"
              />
            )}

            {encord_active_sideCards && (
              <section className="flex flex-col mt-6 lg:flex-row gap-x-6">
                {encord_active_sideCards?.map((card, index) => {
                  return (
                    <SideCard
                      key={index}
                      data={card}
                      containerClasses="!h-auto"
                      imageClasses="px-5"
                      labelClass="text-[#C5BBFF] tracking-wider"
                      cardClass="encord_active_card_split"
                      descriptionClass="text-gray-1600 tracking-tighter_1"
                      headingClass="text-white tracking-tighter_1"
                    />
                  );
                })}
              </section>
            )}

            {(_data?.cta_heading || _data?.cta_description) && (
              <section className="w-full mx-auto my-24 text-center lg:max-w-full sm:max-w-lg">
                <h1 className="text-2xl sm:text-3.5xl font-semibold mb-2 text-white">
                  {_data?.cta_heading?.text}
                </h1>
                <p className="mx-auto mb-5 text-base text-white lg:max-w-xl tracking-tighter_1">
                  {_data?.cta_description?.text}
                </p>

                <section className="flex justify-center">
                  <NewHomeLeadFormWithAPIForm
                    location={location}
                    btnText="Book a demo"
                    isCustomBtnBg={true}
                    btnColor="btn_gradient border-0 px-8 h-[50px]"
                  />
                </section>
              </section>
            )}
          </section>
        </article>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query AllPrismicNewCompetitorLandingPage($uid: String!) {
    allPrismicNewCompetitorLandingPage(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          page_heading {
            text
            html
          }
          hero_section_video {
            url
          }
          body {
            ... on PrismicNewCompetitorLandingPageDataBodyFeatureTable {
              id
              slice_type
              items {
                competitor
                competitor_score {
                  text
                }
                encord
                encord_score {
                  text
                }
                feature_name {
                  text
                }
              }
              primary {
                heading {
                  html
                  text
                }
                competitor_name {
                  text
                }
                encord_logo {
                  alt
                  url
                }
                competitor_logo {
                  alt
                  url
                }
              }
            }
            ... on PrismicNewCompetitorLandingPageDataBodyTrustedBrandsList {
              id
              items {
                brand_image {
                  alt
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
              primary {
                label {
                  html
                  text
                }
              }
              slice_type
            }
            ... on PrismicNewCompetitorLandingPageDataBodyRepeatableCards {
              id
              slice_type
              items {
                learn_more {
                  url
                }
                parent_section
                section_description {
                  html
                  text
                }
                section_heading {
                  text
                }
                section_image {
                  url
                  dimensions {
                    width
                    height
                  }
                }
                section_label {
                  text
                }
                section_video {
                  url
                }
              }
            }
            ... on PrismicNewCompetitorLandingPageDataBodyQualityMetrics {
              id
              slice_type
              primary {
                learn_more {
                  url
                }
                section_description {
                  text
                }
                section_heading {
                  html
                  text
                }
                section_image {
                  alt
                  url
                }
                section_label {
                  html
                  text
                }
                section_video {
                  url
                }
              }
              items {
                icon {
                  url
                  alt
                }
                item {
                  text
                  html
                }
              }
            }
            ... on PrismicNewCompetitorLandingPageDataBodyReviews {
              id
              slice_type
              primary {
                section_label {
                  text
                }
                section_heading {
                  text
                }
                section_description {
                  text
                }
              }
              items {
                review_author {
                  text
                }
                review_description {
                  text
                }
                review_heading {
                  text
                  html
                }
              }
            }
            ... on PrismicNewCompetitorLandingPageDataBodyAnnotationTypes {
              id
              slice_type
              items {
                label_slide_video {
                  url
                }
                label_slide_image {
                  url
                }
                label {
                  html
                  text
                }
                label_icon {
                  alt
                  url
                }
              }
              primary {
                section_description {
                  html
                  text
                }
                section_heading {
                  html
                  text
                }
                section_label {
                  html
                  text
                }
              }
            }
            ... on PrismicNewCompetitorLandingPageDataBodyRepeatableSection {
              id
              slice_type
              primary {
                button_link {
                  url
                }
                button_text {
                  text
                }
                label_icon {
                  url
                }
                section_description {
                  text
                }
                section_heading {
                  text
                }
                type
              }
            }
          }
          cta_description {
            text
            html
          }
          cta_heading {
            text
            html
          }
          hero_section_video {
            url
          }
          meta_description {
            text
          }
          meta_heading {
            text
          }
          page_description {
            text
          }
        }
        uid
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data =
    data?.allPrismicNewCompetitorLandingPage &&
    !!data.allPrismicNewCompetitorLandingPage?.nodes.length &&
    data.allPrismicNewCompetitorLandingPage?.nodes[0]?.data;
    
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
export default Competitor;
